import React, { useState } from "react";
import { cn } from "@bem-react/classname";

import Icon from "../Icon";
import TextInput from "../TextInput";

import { SearchInputProps } from "./SearchInput.types";

import "./SearchInput.scss";

const cnSearchInput = cn("SearchInput");

export const SearchInput: React.FC<SearchInputProps> = ({
  isIconSearchVisible = true,
  placeholder = "",
  id = "",
  onChange = () => null,
  onClick = () => null,
  onClear = () => null,
}) => {
  const [visibleClear, setVisibleClear] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const clear = () => {
    setVisibleClear(false);
    setSearchValue("");
    onChange("");
    onClear();
  };

  const input = (value: string) => {
    setSearchValue(value);
    onChange(value);
    if (value.length > 0) {
      setVisibleClear(true);
    }
  };

  return (
    <div className={cnSearchInput()} id={id}>
      <div className={cnSearchInput("Wrap")}>
        {isIconSearchVisible && (
          <div className={cnSearchInput("Icon")} onClick={() => onClick(searchValue)}>
            <Icon name={"icon-search"} fill={"#A7A7A8"} />
          </div>
        )}
        <TextInput
          isOwnValueState
          value={searchValue}
          className={cnSearchInput("Input", { "icon-search": isIconSearchVisible })}
          placeholder={placeholder}
          onChange={input}
          id={id ? `${id}_input` : ""}
        />
        {visibleClear && (
          <button type="button" className={cnSearchInput("Button")} onClick={clear}>
            <Icon name={"icon-close"} fill={"#A7A7A8"} />
          </button>
        )}
      </div>
    </div>
  );
};

export default SearchInput;
