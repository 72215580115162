import React, { useState } from "react";
import { cn } from "@bem-react/classname";

import { TextInputProps } from "./TextInput.types";

import "./TextInput.scss";

const cnTextInput = cn("TextInput");

const TextInput: React.FC<TextInputProps> = ({
  className = "",
  isDisabled = false,
  isError = false,
  isOwnValueState = false,
  isRequired = false,
  onChange = () => null,
  placeholder = "",
  type = "text",
  value = "",
  ...rest
}) => {
  const [inputValue, setInputValue] = useState(value);

  return (
    <input
      className={cnTextInput({ error: isError }, [className])}
      type={type}
      value={isOwnValueState ? value : inputValue}
      onChange={(e) => {
        if (!isOwnValueState) {
          setInputValue(e.target.value);
        }
        onChange(e.target.value);
      }}
      placeholder={placeholder}
      disabled={isDisabled}
      required={isRequired}
      {...rest}
    />
  );
};

export default TextInput;
