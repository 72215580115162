import React from "react";
import { cn } from "@bem-react/classname";

import Icon from "../Icon";

import { ButtonProps } from "./Button.types";

import "./Button.scss";

export const cnButton = cn("SSUIButton");

const Button: React.FC<ButtonProps> = ({
  children,
  className,
  icon,
  iconRevert,
  size = 40,
  theme = "primary",
  width = "auto",
  isActive = false,
  ...props
}) => {
  return (
    <button
      className={cnButton({ Theme: theme, Size: size, Revert: iconRevert, Active: isActive }, [
        className,
      ])}
      style={{ width: width }}
      {...props}
    >
      {icon && <Icon className={cnButton("Icon")} name={icon} width={16} height={16} />}
      {children ? (
        <span
          className={cnButton("Text", { isIcon: icon ? true : false, isIconRevert: iconRevert })}
        >
          {children}
        </span>
      ) : (
        ""
      )}
    </button>
  );
};

export default Button;
