import React from "react";

import { IconProps } from "./Icon.types";

const Icon: React.FC<IconProps> = ({ onClick, name = "icon-dots", ...props }) => {
  const SVG = require(`!svg-react-loader!ss-img/svg/${name}.svg`);

  return <SVG {...props} onClick={onClick} />;
};

export default Icon;
