import React, { useState } from "react";
import { cn } from "@bem-react/classname";

import Icon from "../Icon";

import { Language, LanguageSelectProps } from "./LanguageSelect.types";

import "./LanguageSelect.scss";

export const cnLanguageSelect = cn("LanguageSelect");

// Controlled element, pass currentLanguage from Parent component

const LanguageSelect: React.FC<LanguageSelectProps> = ({
  setLanguage,
  languages,
  currentLanguage,
  id = "",
}) => {
  const [isOpen, setOpen] = useState(false);

  const onClickHandler = () => setOpen(!isOpen);

  const onLanguageClickHandler = (item: Language) => {
    onClickHandler();
    setLanguage(item);
  };

  return (
    <div className={cnLanguageSelect()} id={id}>
      <button onClick={onClickHandler} type="button">
        <Icon name={`colored/icon-lang-${currentLanguage.code}`} />
      </button>
      {isOpen && (
        <ul>
          {languages.map(
            (item: Language) =>
              item.code !== currentLanguage.code && (
                <li key={item.code}>
                  <button
                    type="button"
                    onClick={() => onLanguageClickHandler(item)}
                    id={id ? `${id}_button_${item.code}` : ""}
                  >
                    <Icon name={`colored/icon-lang-${item.code}`} />
                  </button>
                </li>
              )
          )}
        </ul>
      )}
    </div>
  );
};

export default LanguageSelect;
