import React, { FC } from "react";
import { cn } from "@bem-react/classname";

import { RadioProps } from "./Radio.types";

import "./Radio.scss";

const cnRadio = cn("Radio");

const Radio: FC<RadioProps> = ({ onChange = () => null, ...input }) => {
  return (
    <label className={cnRadio()}>
      <input
        className={cnRadio("Input")}
        type="radio"
        onChange={(event) => onChange(event.currentTarget.value)}
        {...input}
      />
      <span />
    </label>
  );
};

export default Radio;
