import React from "react";
import { cn } from "@bem-react/classname";

import { CheckboxProps, ThemeType } from "./Checkbox.types";

import "./Checkbox.scss";

export const cnCheckbox = cn("Checkbox");
export const cnSwitch = cn("Switch");

const Checkbox: React.FC<CheckboxProps> = ({ theme = "", onChange = () => null, ...input }) => {
  const currentThem = (thm: ThemeType, child = ""): string => {
    switch (thm) {
      case "squre":
        return child ? cnCheckbox(child) : cnCheckbox({ theme: "squre" });
      case "switch":
        return cnSwitch(child);
      default:
        return cnCheckbox(child);
    }
  };

  return (
    <label className={currentThem(theme)}>
      <input
        className={currentThem(theme, "Input")}
        type="checkbox"
        onChange={(event) => onChange(event.target.checked)}
        {...input}
      />
      <span className={theme === "switch" ? cnSwitch(null, ["slider", "round"]) : ""} />
    </label>
  );
};

export default Checkbox;
