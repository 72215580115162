// // Generated with util/create-component.js
// import React from "react";

// import { FolderIconProps } from "./FolderIcon.types";

// import "./FolderIcon.scss";

// const FolderIcon: React.FC<FolderIconProps> = ({ foo }) => (
//     <div data-testid="FolderIcon" className="foo-bar">{foo}</div>
// );

// export default FolderIcon;

import React, { FC } from "react";
import { cn } from "@bem-react/classname";
import Icon from "../Icon";

import { FolderIconProps } from "./FolderIcon.types";

import "./FolderIcon.scss";

const cnFolderIcon = cn("FolderIcon");

//* default icon size in svg
const defaultSize = 30;
//* calculate ration by default svg height
const heightRatio = Math.abs(27 / defaultSize);
//* calculate group icon size by default svg height
const groupRatio = Math.abs(9 / defaultSize);

export const FolderIcon: FC<FolderIconProps> = ({
  access = "",
  className = "",
  handleClick = () => null,
  id = "",
  isGroup,
  isRoot,
  level = 0,
  size = defaultSize,
  style,
}) => {
  const dotSize = Math.round(
    3 + 3 * Math.abs((defaultSize > size ? defaultSize : size) / defaultSize - 1)
  );
  const height = size * heightRatio;
  const groupTop = size * Math.abs(50 / 240) + dotSize;

  return (
    <div
      className={`${cnFolderIcon({ access })} ${className}`}
      id={id}
      onClick={() => handleClick()}
      style={{ width: size, height, ...style }}
    >
      <Icon name="colored/icon-folder" width={size} height={height} />
      {isGroup && (
        <Icon
          className={cnFolderIcon("Group")}
          height={size * groupRatio}
          name="icon-group"
          style={{ top: groupTop, right: dotSize }}
          width={size * groupRatio}
        />
      )}
      {isRoot && (
        <Icon
          className={cnFolderIcon("Root")}
          height={size * groupRatio}
          name="icon-user"
          style={{ top: groupTop, right: dotSize }}
          width={size * groupRatio}
        />
      )}
      {level > 0 && (
        <div
          className={cnFolderIcon("Dots")}
          style={{ bottom: dotSize, right: dotSize, height: dotSize }}
        >
          {new Array(level > 4 ? 4 : level).fill("").map((_, index: number) => (
            <span
              className={cnFolderIcon("Dot")}
              key={index}
              style={{ width: dotSize, height: dotSize, marginLeft: dotSize }}
            >
              &nbsp;
            </span>
          ))}
        </div>
      )}
    </div>
  );
};

export default FolderIcon;
