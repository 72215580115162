import React, { useState } from "react";
import ClickAwayListener from "react-click-away-listener";
import { cn } from "@bem-react/classname";

import Icon from "../Icon";

import { Language, LanguageSelectFieldProps } from "./LanguageSelectField.types";

import "./LanguageSelectField.scss";

export const cnLanguageSelectField = cn("LanguageSelectField");

const LanguageSelectField: React.FC<LanguageSelectFieldProps> = ({
  setLanguage,
  languages,
  id = "",
  currentLanguage,
}) => {
  const [isOpen, setOpen] = useState(false);

  const onClickHandler = () => setOpen(!isOpen);
  const onLanguageClickHandler = (item: Language) => {
    onClickHandler();
    setLanguage(item);
  };

  return (
    <div className={cnLanguageSelectField({ "is-open": isOpen })} id={id}>
      <button onClick={onClickHandler} type="button" className={cnLanguageSelectField("GButton")}>
        <Icon width={16} height={16} name={`colored/icon-lang-${currentLanguage.code}`} />
        <div className={cnLanguageSelectField("Header")}>{currentLanguage.title}</div>
        <div className={cnLanguageSelectField("Icon")}>&nbsp;</div>
      </button>
      {isOpen && (
        <ClickAwayListener onClickAway={() => onClickHandler()}>
          <ul>
            {languages.map(
              (item: Language) =>
                item.code !== currentLanguage.code && (
                  <li key={item.code}>
                    <button
                      type="button"
                      onClick={() => onLanguageClickHandler(item)}
                      id={id ? `${id}_button_${item.code}` : ""}
                    >
                      <Icon name={`colored/icon-lang-${item.code}`} width={16} height={16} />
                      <div className={cnLanguageSelectField("Header")}>{item.title}</div>
                    </button>
                  </li>
                )
            )}
          </ul>
        </ClickAwayListener>
      )}
    </div>
  );
};

export default LanguageSelectField;
