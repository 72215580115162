// Generated with util/create-component.js
import React from "react";
import { cn } from "@bem-react/classname";

import Icon from "../Icon";

import { ILink } from "./Link.types";

import "./Link.scss";

export const cnLink = cn("SS-UI-Link");

const Link: React.FC<ILink> = ({
  className,
  children,
  icon,
  theme = "primary",
  iconRevert,
  size = 14,
  ...props
}) => {
  return (
    <a className={cnLink({ Theme: theme, Size: size, Revert: iconRevert }, [className])} {...props}>
      {icon && <Icon className={cnLink("Icon")} name={icon} />}
      {children ? (
        <span className={cnLink("Text", { isIcon: icon ? true : false, isIconRevert: iconRevert })}>
          {children}
        </span>
      ) : (
        ""
      )}
    </a>
  );
};

export default Link;
